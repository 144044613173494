import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { ToastrService } from "ngx-toastr";
import { environment } from "src/environments/environment";
const httpOptions = {
  headers: new HttpHeaders({
    "Content-Type": "application/json",
    Authorization: "true",
  }),
};
@Injectable({
  providedIn: "root",
})
export class CommonService {
  constructor(private http: HttpClient, private toastr: ToastrService) {}
  public createIntroducerDetail(data) {
    return this.http.post(environment.apiUrl + "/api/details", data);
  }
  public createPersonalDetails(data) {
    return this.http.post(`${environment.apiUrl}/api/personalDetails`, data);
  }
  public createDuplicatePersonalDetails(data1, data) {
    return this.http.post(
      `${environment.apiUrl}/api/duplicatePersonalDetails`,
      data1,
      data
    );
  }
  public createFinancialStatementDetails(data) {
    return this.http.post(
      `${environment.apiUrl}/api/financialStatmentsDetails`,
      data
    );
  }
  public createDuplicateFinancialStatementDetails(data1, data) {
    return this.http.post(
      `${environment.apiUrl}/api/duplicateFinancialStatmentsDetails`,
      data1,
      data
    );
  }
  public createBussinessDetails(data) {
    return this.http.post(`${environment.apiUrl}/api/bussinessDetails`, data);
  }
  public createSection4(data) {
    return this.http.post(`${environment.apiUrl}/api/section4`, data);
  }
  public createDocuments(data: any) {
    return this.http.post(`${environment.apiUrl}/api/documents`, data);
  }
  public createDeclaration(data: any) {
    return this.http.post(`${environment.apiUrl}/api/declarations`, data);
  }
  public getAllDeclarationDetails() {
    return this.http.get(`${environment.apiUrl}/api/declarations`);
  }
  public agentLogin() {
    return this.http.get(`${environment.apiUrl}/api/agents`);
  }
  public login(data) {
    return this.http.post(
      `${environment.apiUrl}/api/agents/login`,
      data,
      httpOptions
    );
  }

  public createMortage(data) {
    return this.http.post(`${environment.apiUrl}/api/mortage`, data);
  }
  public getMortgageDetailBasedOnUser(id) {
    return this.http.get(`${environment.apiUrl}/api/agents/${id}`);
  }
  public getMortageDetails() {
    return this.http.get(`${environment.apiUrl}/api/mortage`);
  }
  public createAgent(data) {
    return this.http.post(`${environment.apiUrl}/api/agents`, data);
  }
  public createCustomer(data) {
    return this.http.post(`${environment.apiUrl}/api/customer`, data);
  }
  public createLoanDetail(data) {
    return this.http.post(`${environment.apiUrl}/api/loan`, data);
  }
  public forgotPassword(data) {
    return this.http.post(
      `${environment.apiUrl}/api/agents/forgotPassword`,
      data
    );
  }
  public resetPassword(data, token) {
    return this.http.patch(
      `${environment.apiUrl}/api/agents/resetPassword/${token}`,
      data
    );
  }

  // Get data from backend
  getDetails() {
    return this.http.get(`${environment.apiUrl}/api/details`);
  }
  getSingleUserDetail(id) {
    return this.http.get(`${environment.apiUrl}/api/agents/${id}`);
  }
  getSingleMortageDetailBasedOnMortageId(id) {
    return this.http.get(`${environment.apiUrl}/api/mortage/${id}`);
  }
  // Bank
  createBankData(data) {
    return this.http.post(`${environment.apiUrl}/api/bank`, data);
  }
  // Toast Messages
  showSuccess(message, title) {
    this.toastr.success(message, title);
  }

  showError(message, title) {
    this.toastr.error(message, title);
  }

  showInfo(message, title) {
    this.toastr.info(message, title);
  }

  showWarning(message, title) {
    this.toastr.warning(message, title);
  }

  isLoggedIn() {
    return localStorage.getItem("authToken");
  }

  // Update Data
  updateDetail(id, data) {
    return this.http.patch(`${environment.apiUrl}/api/details/${id}`, data);
  }
  updatePersonalDetail(id, data) {
    return this.http.patch(
      `${environment.apiUrl}/api/personalDetails/${id}`,
      data
    );
  }
  updateDuplicatePersonalDetail(id, data) {
    return this.http.patch(
      `${environment.apiUrl}/api/duplicatePersonalDetails/${id}`,
      data
    );
  }
  updateDuplicateFinancialDetail(id, data) {
    return this.http.patch(
      `${environment.apiUrl}/api/duplicateFinancialStatmentsDetails/${id}`,
      data
    );
  }
  updateFinancialDetail(id, data) {
    return this.http.patch(
      `${environment.apiUrl}/api/financialStatmentsDetails/${id}`,
      data
    );
  }
  updateBussinessDetail(id, data) {
    return this.http.patch(
      `${environment.apiUrl}/api/bussinessDetails/${id}`,
      data
    );
  }
  updateSection4Detail(id, data) {
    return this.http.patch(`${environment.apiUrl}/api/section4/${id}`, data);
  }
  updateDocumentDetail(id, data) {
    return this.http.patch(`${environment.apiUrl}/api/documents/${id}`, data);
  }
  updateDeclarationDetail(id, data) {
    return this.http.patch(
      `${environment.apiUrl}/api/declarations/${id}`,
      data
    );
  }
}
