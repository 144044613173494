import { Component, Input, OnInit } from "@angular/core";
import { ModalController, NavParams } from "@ionic/angular";
import { CommonService } from "../services/common.service";
@Component({
  selector: "app-customermodal",
  templateUrl: "./customermodal.page.html",
  styleUrls: ["./customermodal.page.scss"],
})
export class CustomermodalPage implements OnInit {
  @Input() public data2: any;
  data: any = [];
  isData: boolean = false;
  constructor(
    private modalController: ModalController,
    navParams: NavParams,
    private commonService: CommonService
  ) {
    this.data2 = navParams.get("data1");
  }

  ngOnInit() {
    console.log(this.data2);

    this.commonService
      .getSingleMortageDetailBasedOnMortageId(this.data2)
      .subscribe((res: any) => {
        console.log(res.data.loandetail);
        if (res.data.loandetail === null) {
          return;
        }
        this.isData = true;
        this.data = res.data.loandetail;
      });
  }
  async closeModal() {
    await this.modalController.dismiss(this.data2);
  }
}
