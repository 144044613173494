import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";

import { IonicModule } from "@ionic/angular";
import { SignaturePadModule } from "angular2-signaturepad";
import { ApplicationForm1PageRoutingModule } from "./application-form1-routing.module";

import { ApplicationForm1Page } from "./application-form1.page";

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    IonicModule,
    SignaturePadModule,
    ApplicationForm1PageRoutingModule,
  ],
  declarations: [ApplicationForm1Page],
})
export class ApplicationForm1PageModule {}
