import { Component, OnInit } from "@angular/core";

import { Platform } from "@ionic/angular";
import { SplashScreen } from "@ionic-native/splash-screen/ngx";
import { StatusBar } from "@ionic-native/status-bar/ngx";
import { Router } from "@angular/router";
import { CommonService } from "./services/common.service";
import { ChartComponent } from "ng-apexcharts";

@Component({
  selector: "app-root",
  templateUrl: "app.component.html",
  styleUrls: ["app.component.scss"],
})
export class AppComponent implements OnInit {
  public selectedIndex = 0;
  public appPages = [
    // {
    //   title: "Dashboard",
    //   url: "/dashboard",
    //   icon: "home",
    // },
    // {
    //   title: "Application Form",
    //   url: "/application-form",
    //   icon: "document",
    // },
    // {
    //   title: "Customer",
    //   url: "/customer",
    //   icon: "home",
    // },
  ];
  // public labels = ['Family', 'Friends', 'Notes', 'Work', 'Travel', 'Reminders'];

  constructor(
    private platform: Platform,
    private splashScreen: SplashScreen,
    private statusBar: StatusBar,
    private router: Router,
    private commonService: CommonService
  ) {
    this.initializeApp();
  }

  initializeApp() {
    this.platform.ready().then(() => {
      this.statusBar.styleDefault();
      this.splashScreen.hide();
    });
  }

  logOut() {
    localStorage.clear();
    // this.router.navigate(["/login"]);
    this.commonService.showWarning("Logout Successfully", "LOGOUT");
    window.location.href = "login";
  }
  ngAfterViewInit() {
    // var userType = localStorage.getItem("roles");
    // console.log(userType);
    // this.router.routeReuseStrategy.shouldReuseRoute = function () {
    //   return false;
    // };
    var userType = localStorage.getItem("roles");
    this.appPages = [];
    console.log(userType);
    if (userType == "agent") {
      this.appPages.push(
        {
          title: "Mortgage Calculator",
          url: "/mortgage-calculator",
          icon: "document",
        },
        {
          title: "Customer",
          url: "/customer",
          icon: "home",
        }
      );
    }
    if (userType == "customer") {
      this.appPages.push({
        title: "Application Form",
        url: "/application-form",
        icon: "document",
      });
    }
  }

  ngOnInit() {
    // var userType = localStorage.getItem("roles");
    // this.appPages = [];
    // console.log(userType);
    // if (userType == "agent") {
    //   this.appPages.push({
    //     title: "Application Form",
    //     url: "/application-form",
    //     icon: "document",
    //   });
    // }
    // if (userType == "customer") {
    //   this.appPages.push({
    //     title: "Customer",
    //     url: "/customer",
    //     icon: "home",
    //   });
    // }
    const roles = localStorage.getItem("roles");
    if (roles == "agent") {
      this.router.navigate(["/mortgage-calculator"]);
    } else {
      this.router.navigate(["/application-form"]);
    }
    const path = window.location.pathname.split("folder/")[1];
    if (path !== undefined) {
      this.selectedIndex = this.appPages.findIndex(
        (page) => page.title.toLowerCase() === path.toLowerCase()
      );
    }
  }
}
