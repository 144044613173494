import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { IonicModule } from '@ionic/angular';

import { CustomermodalPageRoutingModule } from './customermodal-routing.module';

import { CustomermodalPage } from './customermodal.page';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    CustomermodalPageRoutingModule
  ],
  declarations: [CustomermodalPage]
})
export class CustomermodalPageModule {}
