import { NgModule } from "@angular/core";
import { BrowserModule } from "@angular/platform-browser";
import { RouteReuseStrategy } from "@angular/router";

import { IonicModule, IonicRouteStrategy } from "@ionic/angular";
import { SplashScreen } from "@ionic-native/splash-screen/ngx";
import { StatusBar } from "@ionic-native/status-bar/ngx";

import { AppComponent } from "./app.component";
import { AppRoutingModule } from "./app-routing.module";
import { HttpClientModule } from "@angular/common/http";
import { UserService } from "./services/user.service";

import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { CommonModule } from "@angular/common";
import { SignaturePadModule } from "angular2-signaturepad";
import { ToastrModule } from "ngx-toastr";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { NgApexchartsModule } from "ng-apexcharts";
import { AuthGuard } from "./guard/auth.guard";
import { CommonService } from "./services/common.service";
import { CustomermodalPageModule } from "./customermodal/customermodal.module";
import { ApplicationForm1PageModule } from "./application-form1/application-form1.module";
@NgModule({
  declarations: [AppComponent],
  entryComponents: [],
  imports: [
    CommonModule,
    BrowserModule,
    BrowserAnimationsModule,
    ReactiveFormsModule,
    FormsModule,
    CustomermodalPageModule,
    ApplicationForm1PageModule,
    ToastrModule.forRoot(),
    IonicModule.forRoot(),
    AppRoutingModule,
    HttpClientModule,
    SignaturePadModule,
  ],
  providers: [
    StatusBar,
    SplashScreen,
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    UserService,
    AuthGuard,
    CommonService,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
