import { Component, Input, ElementRef, OnInit, ViewChild } from "@angular/core";
import * as pdfMake from "pdfmake/build/pdfmake.js";
import * as pdfFonts from "pdfmake/build/vfs_fonts.js";
(pdfMake as any).vfs = pdfFonts.pdfMake.vfs;
import { IonSlides, NavParams, NavController } from "@ionic/angular";
import { FormGroup, FormBuilder, FormArray } from "@angular/forms";
import { CommonService } from "../services/common.service";
import { SignaturePad } from "angular2-signaturepad";
import { DomSanitizer } from "@angular/platform-browser";

@Component({
  selector: "app-application-form1",
  templateUrl: "./application-form1.page.html",
  styleUrls: ["./application-form1.page.scss"],
})
export class ApplicationForm1Page implements OnInit {
  @Input() public data2: any;
  @ViewChild("slides") slides: IonSlides;
  @ViewChild("UploadFileInput") uploadFileInput: ElementRef;
  @ViewChild("UploadFileInput1", { static: true }) uploadFileInput1: ElementRef;
  @ViewChild("UploadFileInput2") uploadFileInput2: ElementRef;
  @ViewChild("UploadFileInput3") uploadFileInput3: ElementRef;
  @ViewChild(SignaturePad) signaturePad: SignaturePad;

  public signaturePadOptions: Object = {
    minWidth: 5,
    canvasWidth: 500,
    canvasHeight: 300,
  };
  imageurl: any;
  imgData: any;
  files: any;
  file1value: any;
  pdfdata: any;
  constructor(
    public formBuilder: FormBuilder,
    private commonService: CommonService,
    public navCtrl: NavController,
    navParams: NavParams,
    private sanitized: DomSanitizer
  ) {
    this.data2 = navParams.get("data2");
  }
  form: FormGroup;
  form1: FormGroup;
  duplicatePersonal: FormGroup;
  financial: FormGroup;
  financialDuplicate: FormGroup;
  bussiness: FormGroup;
  section4: FormGroup;
  declarationForm: FormGroup;
  fileForm: FormGroup;
  detail: any = [];
  personalDetail: any = [];
  duplicatePersonalDetail: any = [];
  financialDetail: any = [];
  duplicateFinancialDetail: any = [];
  bussinessDetail: any = [];
  bussinessItem: any = [];
  section4Detail: any = [];
  declarationDetail: any = [];
  file1: any;
  file2: any;
  file3: any;
  file4: any;
  file5: any;
  file6: any;
  fileArray: any = [];
  fileArray1: any = [];
  fileArray2: any = [];
  fileArray3: any = [];
  fileArray4: any = [];
  fileArray5: any = [];

  // boolean
  duplicate: boolean = false;
  duplicate1: boolean = false;

  // updated Data
  updatedDetail: any;
  updatedPersonalDetail: any;
  updatedPersonalDuplicateDetail: any;
  updateFinancialDetail: any;
  updateDuplicateFinancialDetailS: any;
  updateBussinessDetailS: any;
  updateSection4DetailS: any;

  ngOnInit() {
    localStorage.getItem("authToken");
    this.createForm();
    this.createPersonalDetailForm();
    this.createPersonalDuplicateDetail();
    this.createFinancialStatmentDetailForm();
    this.createFinancialStatmentDetailDuplicateForm();
    this.createBusinessDetailForm();
    this.createSection4Form();
    this.createDeclarationForm();
    this.createDocumentForm();
    this.getSingleDetail();
    this.getPersonalDetail();
    this.getDuplicatePersonalDetail();
    this.getFinancialDetail();
    this.getDuplicateFinancialDetail();
    this.getBussinessDetail();
    this.getSection4Detail();
    this.getDeclarationDetail();
    this.getSingleDocumentDetail();
    this.getDocumentDefinition();
  }

  createForm() {
    this.form = this.formBuilder.group({
      agentId: this.data2,
      introducerName: [""],
      introducerEmail: [""],
      introducerTelephone: [""],
      accountantName: [""],
      accountantEmail: [""],
      accountantTelephone: [""],
      solicitorName: [""],
      solicitorEmail: [""],
      solicitorTelephone: [""],
    });
  }
  createPersonalDetailForm() {
    this.form1 = this.formBuilder.group({
      agentId: this.data2,
      title: [""],
      firstname: [""],
      middlename: [""],
      sirname: [""],
      dob: [""],
      anticipatedRetirementAge: [""],
      nationality: [""],
      nationalInsuranceNumber: [""],
      martialStatus: [""],
      numberOfDependants: [""],
      homeAddress: [""],
      postcode: [""],
      howLongAtThisAddress: [""],
      telephone: [""],
      email: [""],
      employementStatus: [""],
      jobTitle: [""],
      creditHistroy: [""],
      select1: [""],
      select2: [""],
      select3: [""],
      select4: [""],
      select5: [""],
      select6: [""],
      select7: [""],
    });
  }

  createPersonalDuplicateDetail() {
    this.duplicatePersonal = this.formBuilder.group({
      agentId: this.data2,
      title1: [""],
      firstname1: [""],
      middlename1: [""],
      sirname1: [""],
      dob1: [""],
      anticipatedRetirementAge1: [""],
      nationality1: [""],
      nationalInsuranceNumber1: [""],
      martialStatus1: [""],
      numberOfDependants1: [""],
      homeAddress1: [""],
      postcode1: [""],
      howLongAtThisAddress1: [""],
      telephone1: [""],
      email1: [""],
      employementStatus1: [""],
      jobTitle1: [""],
      creditHistroy1: [""],
      select11: [""],
      select21: [""],
      select31: [""],
      select41: [""],
      select51: [""],
      select61: [""],
      select71: [""],
    });
  }
  createFinancialStatmentDetailForm() {
    this.financial = this.formBuilder.group({
      agentId: this.data2,
      mainResidence: [""],
      totalOtherProp: [""],
      lifePolicies: [""],
      Stock: [""],
      stock2: [""],
      type: [""],
      Other: [""],
      type1: [""],
      totalAssets: [""],
      liabilities: [""],
      mainResidenceMortage: [""],
      lender: [""],
      totalOtherPropertyMortage: [""],
      bankOverdraft: [""],
      loans: [""],
      hpLeasingBalance: [""],
      creditCard: [""],
      provider: [""],
      other1: [""],
      type2: [""],
      type3: [""],
      totalLiabilities: [""],
      totalNetWorth: [""],
      assetMinusLiabilities: [""],
      mainIncome: [""],
      spouseIncome: [""],
      totalRentIncome: [""],
      otherIncome: [""],
      type4: [""],
      totalMonthlyIncome: [""],
      rent: [""],
      otherMortagePayment: [""],
      loanPurchasePayment: [""],
      creditCardPayments: [""],
      CouncilTax: [""],
      lifeInsurance: [""],
      contentsInsurance: [""],
      domesticFuel: [""],
      telephone: [""],
      food: [""],
      carAndTravellingExpense: [""],
      leisure: [""],
      otherExp: [""],
      typeExp: [""],
      totalExpenditure: [""],
      monthlySurplus: [""],
    });
  }

  createFinancialStatmentDetailDuplicateForm() {
    this.financialDuplicate = this.formBuilder.group({
      agentId: this.data2,
      mainResidence1: [""],
      totalOtherProp1: [""],
      lifePolicies1: [""],
      Stock1: [""],
      stock21: [""],
      type1: [""],
      Other1: [""],
      type11: [""],
      totalAssets1: [""],
      liabilities1: [""],
      mainResidenceMortage1: [""],
      lender1: [""],
      totalOtherPropertyMortage1: [""],
      bankOverdraft1: [""],
      loans1: [""],
      hpLeasingBalance1: [""],
      creditCard1: [""],
      provider1: [""],
      other11: [""],
      type21: [""],
      type31: [""],
      totalLiabilities1: [""],
      totalNetWorth1: [""],
      assetMinusLiabilities1: [""],
      mainIncome1: [""],
      spouseIncome1: [""],
      totalRentIncome1: [""],
      otherIncome1: [""],
      type41: [""],
      totalMonthlyIncome1: [""],
      rent1: [""],
      otherMortagePayment1: [""],
      loanPurchasePayment1: [""],
      creditCardPayments1: [""],
      CouncilTax1: [""],
      lifeInsurance1: [""],
      contentsInsurance1: [""],
      domesticFuel1: [""],
      telephone1: [""],
      food1: [""],
      carAndTravellingExpense1: [""],
      leisure1: [""],
      otherExp1: [""],
      typeExp1: [""],
      totalExpenditure1: [""],
      monthlySurplus1: [""],
    });
  }

  createBusinessDetailForm() {
    this.bussiness = this.formBuilder.group({
      agentId: this.data2,
      bussinessType: [""],
      noOfPartners: [""],
      noOfDirectors: [""],
      noOfEmployess: [""],
      tradingName: [""],
      registerCompanyName: [""],
      natureOfBussiness: [""],
      select1: [""],
      select2: [""],
      select3: [""],
      select4: [""],
      select5: [""],
      bussinessBankers: [""],
      bankOverdraft: [""],
      limit: [""],
      items: this.formBuilder.array([
        this.formBuilder.group({
          loanType: [""],
          valueOfAsset: [""],
          outStanding: [""],
          monthlyPayments: [""],
          term: [""],
          additionalInfo: [""],
        }),
      ]),
    });
  }
  setItems(data) {
    console.log(data);

    let arr = new FormArray([]);
    data.forEach((d) => {
      console.log(d);
      arr.push(
        this.formBuilder.group({
          loanType: d.loanType,
          valueOfAsset: d.valueOfAsset,
          outStanding: d.outStanding,
          monthlyPayments: d.monthlyPayments,
          term: d.term,
          additionalInfo: d.additionalInfo,
        })
      );
    });
    return arr;
  }

  createSection4Form() {
    this.section4 = this.formBuilder.group({
      agentId: this.data2,
      borrowerName: [""],
      propertyAddress: [""],
      postcode: [""],
      descOfProp: [""],
      trnure: [""],
      useOfProp: [""],
      purchasePrice: [""],
      outstandingLoanAmount: [""],
      lendRequired: [""],
      lender: [""],
      originalPurchasePrice: [""],
      purchaseDate: [""],
      rentalIncome: [""],
      perAnnum: [""],
      termOfLease: [""],
      select1: [""],
    });
  }

  createDeclarationForm() {
    this.declarationForm = this.formBuilder.group({
      agentId: this.data2,
      name: [""],
      sign: this.declarationDetail.sign,
      // sign1: this.declarationDetail.sign1,
      date: [""],
    });
  }

  createDocumentForm() {
    this.fileForm = this.formBuilder.group({
      agentId: this.data2,
      file1: [],
      file2: [],
      file3: [],
      file4: [],
      file5: [],
      file6: [],
    });
  }

  next() {
    console.log("Next");
    this.slides.slideNext();
  }

  getSingleDetail() {
    this.commonService.getSingleUserDetail(this.data2).subscribe((res: any) => {
      console.log("Single Detail", res.data);
      if (res.data == undefined) {
        return;
      }
      this.detail = res.data.detail;
      this.form.patchValue({
        introducerName: this.detail.introducerName,
        introducerEmail: this.detail.introducerEmail,
        introducerTelephone: this.detail.introducerTelephone,
        accountantName: this.detail.accountantName,
        accountantEmail: this.detail.accountantEmail,
        accountantTelephone: this.detail.accountantTelephone,
        solicitorName: this.detail.solicitorName,
        solicitorEmail: this.detail.solicitorEmail,
        solicitorTelephone: this.detail.solicitorTelephone,
      });
    });
  }
  getPersonalDetail() {
    this.commonService.getSingleUserDetail(this.data2).subscribe((res: any) => {
      console.log("Personal Detail", res.data);

      this.personalDetail = res.data.personalDetail;
      // console.log(this.personalDetail);

      this.form1.patchValue({
        title: this.personalDetail.title,
        firstname: this.personalDetail.firstname,
        middlename: this.personalDetail.middlename,
        sirname: this.personalDetail.sirname,
        dob: this.personalDetail.dob,
        anticipatedRetirementAge: this.personalDetail.anticipatedRetirementAge,
        nationality: this.personalDetail.nationality,
        nationalInsuranceNumber: this.personalDetail.nationalInsuranceNumber,
        martialStatus: this.personalDetail.martialStatus,
        numberOfDependants: this.personalDetail.numberOfDependants,
        homeAddress: this.personalDetail.homeAddress,
        postcode: this.personalDetail.postcode,
        howLongAtThisAddress: this.personalDetail.howLongAtThisAddress,
        telephone: this.personalDetail.telephone,
        email: this.personalDetail.email,
        employementStatus: this.personalDetail.employementStatus,
        jobTitle: this.personalDetail.jobTitle,
        creditHistroy: this.personalDetail.creditHistroy,
        select1: this.personalDetail.select1,
        select2: this.personalDetail.select2,
        select3: this.personalDetail.select3,
        select4: this.personalDetail.select4,
        select5: this.personalDetail.select5,
        select6: this.personalDetail.select6,
        select7: this.personalDetail.select7,
      });
    });
  }
  getDuplicatePersonalDetail() {
    this.commonService.getSingleUserDetail(this.data2).subscribe((res: any) => {
      console.log(
        "Duplicate Personal Detail",
        res.data.duplicatePersonalDetail
      );

      if (
        res.data.duplicatePersonalDetail &&
        res.data.duplicatePersonalDetail !== undefined
      ) {
        this.duplicate = true;
        this.duplicatePersonalDetail = res.data.duplicatePersonalDetail;
        this.duplicatePersonal.patchValue({
          title1: this.duplicatePersonalDetail.title1,
          firstname1: this.duplicatePersonalDetail.firstname1,
          middlename1: this.duplicatePersonalDetail.middlename1,
          sirname1: this.duplicatePersonalDetail.sirname1,
          dob1: this.duplicatePersonalDetail.dob1,
          anticipatedRetirementAge1:
            this.duplicatePersonalDetail.anticipatedRetirementAge1,
          nationality1: this.duplicatePersonalDetail.nationality1,
          nationalInsuranceNumber1:
            this.duplicatePersonalDetail.nationalInsuranceNumber1,
          martialStatus1: this.duplicatePersonalDetail.martialStatus1,
          numberOfDependants1: this.duplicatePersonalDetail.numberOfDependants1,
          homeAddress1: this.duplicatePersonalDetail.homeAddress1,
          postcode1: this.duplicatePersonalDetail.postcode1,
          howLongAtThisAddress1:
            this.duplicatePersonalDetail.howLongAtThisAddress1,
          telephone1: this.duplicatePersonalDetail.telephone1,
          email1: this.duplicatePersonalDetail.email1,
          employementStatus1: this.duplicatePersonalDetail.employementStatus1,
          jobTitle1: this.duplicatePersonalDetail.jobTitle1,
          creditHistroy1: this.duplicatePersonalDetail.creditHistroy1,
          select11: this.duplicatePersonalDetail.select11,
          select21: this.duplicatePersonalDetail.select21,
          select31: this.duplicatePersonalDetail.select31,
          select41: this.duplicatePersonalDetail.select41,
          select51: this.duplicatePersonalDetail.select51,
          select61: this.duplicatePersonalDetail.select61,
          select71: this.duplicatePersonalDetail.select71,
        });
      }
    });
  }

  getFinancialDetail() {
    this.commonService.getSingleUserDetail(this.data2).subscribe((res: any) => {
      console.log("Financial Detail", res.data);

      this.financialDetail = res.data.financialStatement;

      this.financial.patchValue({
        mainResidence: this.financialDetail.mainResidence,
        totalOtherProp: this.financialDetail.totalOtherProp,
        lifePolicies: this.financialDetail.lifePolicies,
        Stock: this.financialDetail.Stock,
        stock2: this.financialDetail.stock2,
        type: this.financialDetail.type,
        Other: this.financialDetail.Other,
        type1: this.financialDetail.type1,
        totalAssets: this.financialDetail.totalAssets,
        liabilities: this.financialDetail.liabilities,
        mainResidenceMortage: this.financialDetail.mainResidenceMortage,
        lender: this.financialDetail.lender,
        totalOtherPropertyMortage:
          this.financialDetail.totalOtherPropertyMortage,
        bankOverdraft: this.financialDetail.bankOverdraft,
        loans: this.financialDetail.loans,
        hpLeasingBalance: this.financialDetail.hpLeasingBalance,
        creditCard: this.financialDetail.creditCard,
        provider: this.financialDetail.provider,
        other1: this.financialDetail.other1,
        type2: this.financialDetail.type2,
        type3: this.financialDetail.type3,
        totalLiabilities: this.financialDetail.totalLiabilities,
        totalNetWorth: this.financialDetail.totalNetWorth,
        assetMinusLiabilities: this.financialDetail.assetMinusLiabilities,
        mainIncome: this.financialDetail.mainIncome,
        spouseIncome: this.financialDetail.spouseIncome,
        totalRentIncome: this.financialDetail.totalRentIncome,
        otherIncome: this.financialDetail.otherIncome,
        type4: this.financialDetail.type4,
        totalMonthlyIncome: this.financialDetail.totalMonthlyIncome,
        rent: this.financialDetail.rent,
        otherMortagePayment: this.financialDetail.otherMortagePayment,
        loanPurchasePayment: this.financialDetail.loanPurchasePayment,
        creditCardPayments: this.financialDetail.creditCardPayments,
        CouncilTax: this.financialDetail.CouncilTax,
        lifeInsurance: this.financialDetail.lifeInsurance,
        contentsInsurance: this.financialDetail.contentsInsurance,
        domesticFuel: this.financialDetail.domesticFuel,
        telephone: this.financialDetail.telephone,
        food: this.financialDetail.food,
        carAndTravellingExpense: this.financialDetail.carAndTravellingExpense,
        leisure: this.financialDetail.leisure,
        otherExp: this.financialDetail.otherExp,
        typeExp: this.financialDetail.typeExp,
        totalExpenditure: this.financialDetail.totalExpenditure,
        monthlySurplus: this.financialDetail.monthlySurplus,
      });
    });
  }

  getDuplicateFinancialDetail() {
    this.commonService.getSingleUserDetail(this.data2).subscribe((res: any) => {
      if (
        res.data.duplicateFinancialStatementDetail &&
        res.data.duplicateFinancialStatementDetail !== undefined
      ) {
        this.duplicate1 = true;
        this.duplicateFinancialDetail =
          res.data.duplicateFinancialStatementDetail;
        this.financialDuplicate.patchValue({
          mainResidence1: this.duplicateFinancialDetail.mainResidence1,
          totalOtherProp1: this.duplicateFinancialDetail.totalOtherProp1,
          lifePolicies1: this.duplicateFinancialDetail.lifePolicies1,
          Stock1: this.duplicateFinancialDetail.Stock1,
          stock21: this.duplicateFinancialDetail.stock21,
          type1: this.duplicateFinancialDetail.type1,
          Other1: this.duplicateFinancialDetail.Other1,
          type11: this.duplicateFinancialDetail.type11,
          totalAssets1: this.duplicateFinancialDetail.totalAssets1,
          liabilities1: this.duplicateFinancialDetail.liabilities1,
          mainResidenceMortage1:
            this.duplicateFinancialDetail.mainResidenceMortage1,
          lender1: this.duplicateFinancialDetail.lender1,
          totalOtherPropertyMortage1:
            this.duplicateFinancialDetail.totalOtherPropertyMortage1,
          bankOverdraft1: this.duplicateFinancialDetail.bankOverdraft1,
          loans1: this.duplicateFinancialDetail.loans1,
          hpLeasingBalance1: this.duplicateFinancialDetail.hpLeasingBalance1,
          creditCard1: this.duplicateFinancialDetail.creditCard1,
          provider1: this.duplicateFinancialDetail.provider1,
          other11: this.duplicateFinancialDetail.other11,
          type21: this.duplicateFinancialDetail.type21,
          type31: this.duplicateFinancialDetail.type31,
          totalLiabilities1: this.duplicateFinancialDetail.totalLiabilities1,
          totalNetWorth1: this.duplicateFinancialDetail.totalNetWorth1,
          assetMinusLiabilities1:
            this.duplicateFinancialDetail.assetMinusLiabilities1,
          mainIncome1: this.duplicateFinancialDetail.mainIncome1,
          spouseIncome1: this.duplicateFinancialDetail.spouseIncome1,
          totalRentIncome1: this.duplicateFinancialDetail.totalRentIncome1,
          otherIncome1: this.duplicateFinancialDetail.otherIncome1,
          type41: this.duplicateFinancialDetail.type41,
          totalMonthlyIncome1:
            this.duplicateFinancialDetail.totalMonthlyIncome1,
          rent1: this.duplicateFinancialDetail.rent1,
          otherMortagePayment1:
            this.duplicateFinancialDetail.otherMortagePayment1,
          loanPurchasePayment1:
            this.duplicateFinancialDetail.loanPurchasePayment1,
          creditCardPayments1:
            this.duplicateFinancialDetail.creditCardPayments1,
          CouncilTax1: this.duplicateFinancialDetail.CouncilTax1,
          lifeInsurance1: this.duplicateFinancialDetail.lifeInsurance1,
          contentsInsurance1: this.duplicateFinancialDetail.contentsInsurance1,
          domesticFuel1: this.duplicateFinancialDetail.domesticFuel1,
          telephone1: this.duplicateFinancialDetail.telephone1,
          food1: this.duplicateFinancialDetail.food1,
          carAndTravellingExpense1:
            this.duplicateFinancialDetail.carAndTravellingExpense1,
          leisure1: this.duplicateFinancialDetail.leisure1,
          otherExp1: this.duplicateFinancialDetail.otherExp1,
          typeExp1: this.duplicateFinancialDetail.typeExp1,
          totalExpenditure1: this.duplicateFinancialDetail.totalExpenditure1,
          monthlySurplus1: this.duplicateFinancialDetail.monthlySurplus1,
        });
      }
    });
  }

  getBussinessDetail() {
    this.commonService.getSingleUserDetail(this.data2).subscribe((res: any) => {
      this.bussinessDetail = res.data.bussinessDetail;
      // console.log(this.bussinessDetail);

      this.bussinessItem = this.bussinessDetail.items;
      let items = this.bussinessItem; // value from db

      const formArray = new FormArray([]);

      items.forEach((s) => {
        formArray.push(
          this.formBuilder.group({
            loanType: s.loanType,
            valueOfAsset: s.valueOfAsset,
            outStanding: s.outStanding,
            monthlyPayments: s.monthlyPayments,
            term: s.term,
            additionalInfo: s.additionalInfo,
          })
        );
      });
      this.bussiness.setControl("items", formArray);
      this.bussiness.patchValue({
        bussinessType: this.bussinessDetail.bussinessType,
        noOfPartners: this.bussinessDetail.noOfPartners,
        noOfDirectors: this.bussinessDetail.noOfDirectors,
        noOfEmployess: this.bussinessDetail.noOfEmployess,
        tradingName: this.bussinessDetail.tradingName,
        registerCompanyName: this.bussinessDetail.registerCompanyName,
        natureOfBussiness: this.bussinessDetail.natureOfBussiness,
        select1: this.bussinessDetail.select1,
        select2: this.bussinessDetail.select2,
        select3: this.bussinessDetail.select3,
        select4: this.bussinessDetail.select4,
        select5: this.bussinessDetail.select5,
        bussinessBankers: this.bussinessDetail.bussinessBankers,
        bankOverdraft: this.bussinessDetail.bankOverdraft,
        limit: this.bussinessDetail.limit,
      });
    });
  }

  getSection4Detail() {
    this.commonService.getSingleUserDetail(this.data2).subscribe((res: any) => {
      this.section4Detail = res.data.section4;

      this.section4.patchValue({
        borrowerName: this.section4Detail.borrowerName,
        propertyAddress: this.section4Detail.propertyAddress,
        postcode: this.section4Detail.postcode,
        descOfProp: this.section4Detail.descOfProp,
        trnure: this.section4Detail.trnure,
        useOfProp: this.section4Detail.useOfProp,
        purchasePrice: this.section4Detail.purchasePrice,
        outstandingLoanAmount: this.section4Detail.outstandingLoanAmount,
        lendRequired: this.section4Detail.lendRequired,
        lender: this.section4Detail.lender,
        originalPurchasePrice: this.section4Detail.originalPurchasePrice,
        purchaseDate: this.section4Detail.purchaseDate,
        rentalIncome: this.section4Detail.rentalIncome,
        perAnnum: this.section4Detail.perAnnum,
        termOfLease: this.section4Detail.termOfLease,
        select1: this.section4Detail.select1,
      });
    });
  }

  getDeclarationDetail() {
    this.commonService.getSingleUserDetail(this.data2).subscribe((res: any) => {
      this.declarationDetail = res.data.declaration;
      let TYPED_ARRAY = new Uint8Array(this.declarationDetail.sign.data);
      const STRING_CHAR = String.fromCharCode.apply(null, TYPED_ARRAY);
      this.imageurl = STRING_CHAR;
      // let base64String = btoa(STRING_CHAR);
      // console.log(base64String);
      this.declarationForm.patchValue({
        name: this.declarationDetail.name,
        date: this.declarationDetail.date,
      });
    });
  }

  getSingleDocumentDetail() {
    this.commonService.getSingleUserDetail(this.data2).subscribe((res: any) => {
      // console.log(res.data.document);
      this.file1 = res.data.document.file1;
      this.file1.forEach((f) => {
        this.fileForm.patchValue({
          file1: f,
        });
      });
      this.file2 = res.data.document.file2;
      this.file2.forEach((f) => {
        this.fileForm.patchValue({
          file2: f,
        });
      });
      // console.log(this.file2);
      this.file3 = res.data.document.file3;
      this.file3.forEach((f) => {
        this.fileForm.patchValue({
          file3: f,
        });
      });
      this.file4 = res.data.document.file4;
      this.file4.forEach((f) => {
        this.fileForm.patchValue({
          file4: f,
        });
      });
      this.file5 = res.data.document.file5;
      this.file5.forEach((f) => {
        this.fileForm.patchValue({
          file5: f,
        });
      });
      this.file6 = res.data.document.file6;
      this.file6.forEach((f) => {
        this.fileForm.patchValue({
          file6: f,
        });
      });
    });
  }

  updateDetailData() {
    console.log("Updated");
    this.commonService
      .updateDetail(this.data2, this.form.value)
      .subscribe((res: any) => {
        console.log(res.update[0]);
        // localStorage.setItem("detail", JSON.stringify(res.update[0]));
        this.updatedDetail = res.update[0];
      });
    this.next();
  }

  updatePersonalData() {
    this.commonService
      .updatePersonalDetail(this.data2, this.form1.value)
      .subscribe((res: any) => {
        console.log(res);
        this.updatedPersonalDetail = res.update[0];
      });
    this.next();
  }

  UpdatePersonalDuplicateDetail() {
    this.commonService
      .updateDuplicatePersonalDetail(this.data2, this.duplicatePersonal.value)
      .subscribe((res: any) => {
        console.log(res);
        this.updatedPersonalDuplicateDetail = res.update[0];
      });
    this.next();
  }

  updateFinancialDetails() {
    this.commonService
      .updateFinancialDetail(this.data2, this.financial.value)
      .subscribe((res: any) => {
        console.log(res);
        this.updateFinancialDetail = res.update[0];
      });
    this.slides.slideNext();
  }

  updateDuplicateFinancialDetail() {
    this.commonService
      .updateDuplicateFinancialDetail(this.data2, this.financialDuplicate.value)
      .subscribe((res: any) => {
        console.log(res);
        this.updateDuplicateFinancialDetailS = res.update[0];
      });
    this.next();
  }

  updateBussinessDetail() {
    this.commonService
      .updateBussinessDetail(this.data2, this.bussiness.value)
      .subscribe((res: any) => {
        console.log(res);
        this.updateBussinessDetailS = res.update[0];
      });
    this.slides.slideNext();
  }

  updateSection4Detail() {
    this.commonService
      .updateSection4Detail(this.data2, this.section4.value)
      .subscribe((res: any) => {
        console.log(res);
        this.updateSection4DetailS = res.update[0];
      });
    this.slides.slideNext();
  }
  updateDeclarationDetail() {
    this.commonService
      .updateDeclarationDetail(this.data2, this.declarationForm.value)
      .subscribe((res: any) => {
        console.log(res);
      });
    this.slides.slideNext();
  }

  onFileChange(event) {
    var file1 = event.target.files && event.target.files[0];
    if (file1) {
      for (let i of event.target.files) {
        this.fileForm.get("file1").patchValue(i);
        this.fileArray.push(this.fileForm.get("file1").value);
      }
    }
  }
  onFileChange1(event) {
    var file2 = event.target.files && event.target.files[0];
    console.log(file2);

    if (file2) {
      for (let i of event.target.files) {
        this.fileForm.get("file2").patchValue(i);
        this.fileArray1.push(this.fileForm.get("file2").value);
      }
    }
    this.fileForm.get("file2").updateValueAndValidity();
  }
  onFileChange2(event) {
    if (event.target.files && event.target.files[0]) {
      for (let i of event.target.files) {
        this.fileForm.get("file3").patchValue(i);
        this.fileArray2.push(this.fileForm.get("file3").value);
      }
    }
  }

  onFileChange3(event) {
    if (event.target.files && event.target.files[0]) {
      for (let i of event.target.files) {
        this.fileForm.get("file4").patchValue(i);
        this.fileArray3.push(this.fileForm.get("file4").value);
      }
    }
  }
  onFileChange4(event) {
    if (event.target.files && event.target.files[0]) {
      for (let i of event.target.files) {
        this.fileForm.get("file5").patchValue(i);
        this.fileArray4.push(this.fileForm.get("file5").value);
      }
    }
  }
  onFileChange5(event) {
    if (event.target.files && event.target.files[0]) {
      for (let i of event.target.files) {
        this.fileForm.get("file6").patchValue(i);
        this.fileArray5.push(this.fileForm.get("file6").value);
      }
    }
  }
  updateFileForm() {
    this.fileForm.get("agentId").setValue(this.data2);
    let formData: FormData = new FormData();
    formData.append("agentId", this.data2);
    this.fileArray.forEach((file) => {
      formData.append("file1", file);
    });
    console.log(this.fileArray);

    this.fileArray1.forEach((file) => {
      formData.append("file2", file);
    });
    this.fileArray2.forEach((file) => {
      formData.append("file3", file);
    });
    this.fileArray3.forEach((file) => {
      formData.append("file4", file);
    });
    this.fileArray4.forEach((file) => {
      formData.append("file5", file);
    });
    this.fileArray5.forEach((file) => {
      formData.append("file6", file);
    });
    let obj = {
      formData,
    };
    console.log(obj);
    this.commonService
      .updateDocumentDetail(this.data2, obj.formData)
      .subscribe((res: any) => {
        console.log(res);
        res.data = this.fileForm.value;
      });
    this.slides.slideNext();
  }

  getDocumentDefinition() {
    // const data = JSON.parse(localStorage.getItem("detail"));
    this.pdfdata = {
      content: [
        {
          text: "Application Form Part:1",

          fontSize: 25,
          italics: true,
          alignment: "left",
          margin: [0, 0, 20, 0],
          color: "darkblue",
        },
        {
          text: "To be completed by the client",

          fontSize: 8,
          italics: true,
          alignment: "left",
          margin: [20, 0, 0, 0],
        },
        {
          alignment: "justify",
          columns: [
            {
              table: {
                heights: [10, 30, 20],
                widths: [290],
                body: [
                  [
                    [
                      {
                        text: "PROFESSIONAL DETAILS",
                        style: "tableHeader",
                        alignment: "center",
                      },
                    ],
                  ],
                  // ["PROFESSIONAL DETAILS"],
                  // [
                  //   [
                  //     {
                  //       text: "PROFESSIONAL DETAILS",
                  //       alignment: "center",
                  //       bold: true,
                  //       italics: true,
                  //     },
                  //   ],
                  // ],
                  [this.getProfessionalIntroducerObject()],
                  [this.getProfessionalAccountantObject()],
                  [this.getProfessionalSOLICITORObject()],
                ],
              },
              layout: {
                fillColor: function (rowIndex, node, columnIndex) {
                  return rowIndex === 0 ? "#CCCCCC" : null;
                },
              },
            },

            {
              table: {
                heights: [20, 30, 20],
                // widths: [190],
                margin: [0, 50, 0, 0],
                body: [
                  [
                    [
                      {
                        text: "SUPPORTING DOCUMENTS SUPPLIED:",
                        alignment: "center",
                      },
                    ],
                  ],
                  [
                    {
                      stack: [
                        {
                          ul: [
                            {
                              text: "3 Years Accounts/ Business Plan",
                              listType: "square",
                            },
                            {
                              text: "IncomeSA302/ Payslips/P60",
                              listType: "square",
                            },
                            {
                              text: "6 months Business Bank Statements",
                              listType: "square",
                            },
                            {
                              text: "6 months Personal Bank Statements",
                              listType: "square",
                            },
                            {
                              text: "A copy of all Lease Agreements",
                              listType: "square",
                            },
                            {
                              text: "Certified ID and Proof of Address",
                              listType: "square",
                            },
                          ],
                        },
                      ],
                    },
                  ],
                ],
              },
              layout: {
                fillColor: function (rowIndex, node, columnIndex) {
                  console.log(rowIndex, node, columnIndex);

                  return rowIndex % 2 === 0 ? "#CCCCCC" : null;
                },
              },
            },
          ],
        },
        "\n",
        "\n",
        "\n",
        "\n",
        "\n",
        "\n",
        {
          columns: [[this.getPersonalObject()], [this.getPersonalObject1()]],
        },
        "\n",
        {
          columns: [[this.getFinancialObject()], [this.getFinancialObject1()]],
        },
        "\n",
        {
          columns: [[this.getBussinessDetailObject()]],
        },
        "\n",
        "\n",
        {
          columns: [[this.getSection4Object()]],
        },
        "\n",
        {
          columns: [[this.getDeclarationObject()]],
        },
      ],
      images: {},
      styles: {
        small: {
          fontSize: 9,
          italics: true,
          margin: [0, 5, 0, 0],
        },
        small1: {
          fontSize: 10,
          italics: true,
          margin: [0, 5, 0, 0],
        },
        small2: {
          fontSize: 7,
          italics: true,
          margin: [0, 5, 0, 0],
        },
      },
      defaultStyle: {
        columnGap: 10,
        rowGap: 5,
      },
    };
    // pdfMake.createPdf(data).download('file.pdf')
  }

  getProfessionalIntroducerObject() {
    const data = this.updatedDetail;
    console.log(data);
    if (!data) {
      return;
    }
    const exs = [];

    exs.push([
      {
        columns: [
          [
            {
              text: "INTRODUCER please complete part 3 of the application form",
              fontSize: 8,
            },
            {
              text: `Name:${data.introducerName}`,
              italics: true,
              fontSize: 9,
            },
            {
              text: `email:${data.introducerEmail}`,
              italics: true,
              fontSize: 9,
            },
          ],
          [
            {
              text: `Telephone: ${data.introducerTelephone}`,
              italics: true,
              fontSize: 9,
              alignment: "left",
            },
          ],
        ],
      },
    ]);

    return {
      table: {
        widths: ["*"],
        body: [...exs],
      },
    };
  }

  getProfessionalAccountantObject() {
    const data = this.updatedDetail;
    if (!data) {
      return;
    }
    console.log(data);
    const exs = [];

    exs.push([
      {
        columns: [
          [
            {
              text: "ACCOUNTANT",
              fontSize: 8,
              bold: true,
              italics: true,
            },
            {
              text: `Name:${data.accountantName}`,
              fontSize: 8,
              italics: true,
            },
            {
              text: `email:${data.accountantEmail}`,
              fontSize: 8,
              italics: true,
            },
          ],
          [
            {
              text: `Telephone:${data.accountantTelephone} `,
              alignment: "left",
              fontSize: 8,
              italics: true,
            },
          ],
        ],
      },
    ]);

    return {
      table: {
        widths: ["*"],
        body: [...exs],
      },
    };
  }

  getProfessionalSOLICITORObject() {
    const data = this.updatedDetail;
    console.log(data);
    if (!data) {
      return;
    }
    const exs = [];

    exs.push([
      {
        columns: [
          [
            {
              text: "SOLICITOR",
              fontSize: 8,
              bold: true,
              italics: true,
            },
            {
              text: `Name: ${data.solicitorName}`,
            },
            {
              text: `email: ${data.solicitorEmail}`,
            },
          ],
          [
            {
              text: `Telephone: ${data.solicitorTelephone}`,
              alignment: "left",
            },
          ],
        ],
      },
    ]);

    return {
      table: {
        widths: ["*"],
        body: [...exs],
      },
    };
  }

  getBussinessDetailObject() {
    const data = this.updateBussinessDetailS;
    console.log(data);
    if (!data) {
      return;
    }
    const items = this.updateBussinessDetailS.items;
    const exs = [];
    exs.push([
      {
        columns: [
          [
            [
              {
                text: "SECTION 3:BUSSINESS DETAILS",
                alignment: "center",
              },
              {
                text:
                  `Business Type: ${data.bussinessType}` +
                  "\t\t\t\t\t\t\t\t" +
                  `No. of partners/directors : ${data.noOfDirectors}` +
                  "\t\t\t\t\t\t\t\t" +
                  `__ no. of employees: ${data.noOfEmployess}`,
                style: ["small"],
              },
              {
                text: `Trading Name: ${data.tradingName}`,
                style: ["small"],
              },
              {
                text: `Registered company name (if different): ${data.registerCompanyName}`,
                style: ["small"],
              },
              {
                text: `Nature of business: ${data.natureOfBussiness}`,
                style: ["small"],
              },
              {
                text: `"Have the business ever (please delete)",`,
                bold: true,
                italics: true,
              },

              {
                text: `Made a composition with creditors? :${data.select1}`,
                style: ["small1"],
              },
              {
                text: `Failed to keep up repayments on a mortgage, credit card or other financial agreement?: ${data.select2}`,
                style: ["small1"],
              },
              {
                text: `Had a court order or debt registered against it?: ${data.select3}`,
                style: ["small1"],
              },
              {
                text: `Had a property repossessed?: ${data.select4}`,
                style: ["small1"],
              },
              {
                text: `Broken any credit agreements?: ${data.select5}`,
                style: ["small1"],
              },
              {
                text: '"If yes to any of the above questions please provide full details in the additional information section',
                bold: true,
                italics: true,
              },
              {
                text: `Business Bankers: ${data.bussinessBankers}`,
                style: ["small"],
              },
              {
                text:
                  `Bank overdraft: ${data.bankOverdraft}` +
                  "current outstanding amount £  : " +
                  `Limit £: ${data.limit}`,
                style: ["small"],
              },
              {
                table: {
                  widths: ["*", "*", "*", "*", "*", "*"],
                  body: [
                    [
                      "Loan Type (e.g. asset finance/mortgage/ loan)",
                      "Value of Asset(£) (if secured)",
                      "Outstanding (£)",
                      "Monthly Payments",
                      "Term",
                      "Additional Info:",
                    ],
                  ],
                },
              },

              [this.getData(items)],
            ],
          ],
        ],
      },
    ]);

    return {
      table: {
        widths: ["*"],
        body: [...exs],
      },
    };
  }
  getData(data) {
    if (!data) {
      return;
    }
    let phases = {
      table: {
        // dontBreakRows: true,
        widths: ["*", "*", "*", "*", "*", "*"],
        body: [],
      },
    };
    data.forEach((d) => {
      phases.table.body.push([
        {
          text: d.loanType,
        },
        {
          text: d.valueOfAsset,
        },
        {
          text: d.outStanding,
        },
        {
          text: d.monthlyPayments,
        },
        {
          text: d.term,
        },
        {
          text: d.additionalInfo,
        },
      ]);
    });
    return phases;
  }
  getSection4Object() {
    const data = this.updateSection4DetailS;
    if (!data) {
      return;
    }
    const exs = [];
    exs.push([
      {
        columns: [
          [
            [
              {
                text: "SECTION 4:LOAN DETAILS",
                alignment: "center",
              },
              {
                text: `Borrower Name/ Entity: ${data.borrowerName}`,
                style: ["small"],
              },
              {
                text:
                  `Property Address: ${data.propertyAddress}` +
                  "\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t" +
                  `Postcode: ${data.postcode}`,
                style: ["small"],
              },
              {
                text: `Description of property: ${data.descOfProp}`,
                style: ["small"],
              },
              {
                text: `Tenure: ${data.trnure}`,
                style: ["small"],
              },
              {
                text: `Use of property: ${data.useOfProp}`,
                style: ["small"],
              },

              {
                text:
                  `Purchase Price/ Value: ${data.purchasePrice}£` +
                  "\t\t\t\t\t\t\t\t\t\t\t\t" +
                  `Outstanding Loan Amount: ${data.outstandingLoanAmount}£`,
                style: ["small"],
              },
              {
                text:
                  `Lend Required: ${data.lendRequired}£` +
                  "\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t" +
                  `Lender: ${data.lender}`,
                style: ["small"],
              },
              {
                text:
                  `Orignal Purchase Price: ${data.originalPurchasePrice}£` +
                  "\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t" +
                  `Purchase Date: ${data.purchaseDate}£`,
                style: ["small"],
              },
              {
                text: `Rental Income: ${data.perAnnum}`,
                style: ["small"],
              },
              {
                text:
                  `Broken any credit agreements? YES / NO` +
                  "\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t" +
                  `Term of Lease: ${data.termOfLease}`,
                style: ["small"],
              },
              {
                text: `Are there any other charges secured against this property? If yes, please provide details: ${data.select1}`,
                style: ["small"],
              },
              {
                text: `If additional security is being offered please provide detail`,
                italics: true,
                fontSize: 7,
              },
            ],
          ],
        ],
      },
    ]);

    return {
      table: {
        widths: ["*"],
        body: [...exs],
      },
    };
  }
  getDeclarationObject() {
    const exs = [];
    exs.push([
      {
        columns: [
          [
            [
              {
                text: "DECLARATION",
                alignment: "center",
              },
              {
                text: `
                By signing this declaration, I/We confirm that:\n
                - The information contained in this application is true and correct;\n
                - The supporting information is true and correct;\n
                - I/We hereby authorise for our information to be supplied to the appropriate representatives and fraud agencies to carry out credit searches and
                related checks as required\n`,
                style: ["small1"],
              },
              "\n",
              {
                text:
                  `BORROWER 1..........` +
                  "\t\t\t\t\t\t\t\t" +
                  `BORROWER 2...........` +
                  "\t\t\t\t\t\t\t\t" +
                  `DATE..............`,
                bold: true,
                fontSize: 9,
              },
            ],
          ],
        ],
      },
    ]);

    return {
      table: {
        widths: ["*"],
        body: [...exs],
      },
    };
  }
  getPersonalObject() {
    const data = this.updatedPersonalDetail;
    if (!data) {
      return;
    }
    console.log(data);

    const exs = [];

    exs.push([
      {
        columns: [
          [
            {
              text: "SECTION 1: PERSONAL DETAIL",
              bold: true,
              fontSize: 8,
              alignment: "center",
              // margin: [0, 0, 50, 0],
            },
            "\n",
            {
              text: "BORROWER/ DIRECTOR 1 DETAILS",
              bold: true,
              fontSize: 8,
              alignment: "center",
              // margin: [0, 0, 50, 0],
            },
            "\n",
            {
              text: `Title: Mr/ Mrs/ Miss/ Ms/ Other: ${data.title}`,
              style: ["small1"],
            },
            {
              text: `First Name: ${data.firstname}`,
              style: ["small1"],
            },
            {
              text: `Middle Name: ${data.middlename}`,
              style: ["small1"],
            },
            {
              text: `Surname: ${data.sirname}`,
              style: ["small1"],
            },
            {
              text: `Date of Birth: ${data.dob}`,
              style: ["small1"],
            },
            {
              text: `Anticapted Retirement Age: ${data.anticipatedRetirementAge}`,
              style: ["small1"],
            },
            {
              text: `Nationality:: ${data.nationality}`,
              style: ["small1"],
            },
            {
              text: `National Insurance Number: ${data.nationalInsuranceNumber}`,
              style: ["small1"],
            },
            {
              text: `Marital Status: ${data.martialStatus}`,
              style: ["small1"],
            },
            {
              text: `No of Dependants: ${data.numberOfDependants}`,
              style: ["small1"],
            },
            {
              text: `Home Address: ${data.homeAddress}`,
              style: ["small1"],
            },
            {
              text: `Postcode: ${data.postcode}`,
              style: ["small1"],
            },
            {
              text: `How long at this address: years months:${data.howLongAtThisAddress}`,
              style: ["small1"],
            },
            {
              text: `Telephone: ${data.telephone}`,
              style: ["small1"],
            },
            {
              text: `Email:  ${data.email}`,
              style: ["small1"],
            },
            {
              text: `Employment Status: ${data.employementStatus}`,
              style: ["small1"],
            },
            {
              text: `Job Title: ${data.jobTitle}`,
              style: ["small1"],
            },
            {
              text: `CREDIT HISTORY, have you ever: ${data.creditHistroy} `,
              style: ["small1"],
            },
            {
              text: `Been declared bankrupt?: ${data.select1}`,
              style: ["small2"],
            },
            {
              text: `Failed to keep up with repayments on a mortgage, credit card or
              other financial agreement?: ${data.select2}`,
              style: ["small2"],
            },
            {
              text: `Had a court order or debt registered against you?:  ${data.select3}`,
              style: ["small2"],
            },
            {
              text: `Had a property repossessed?: ${data.select4}`,
              style: ["small2"],
            },
            {
              text: `Broken any credit agreements?:  ${data.select5}`,
              style: ["small2"],
            },
            {
              text: `Been subject to an individual voluntary agreement (IVA)?: ${data.select6}`,
              style: ["small2"],
            },
            {
              text: `Been associated with a business that has been involved in one or
              more of the above?:  ${data.select7}`,
              style: ["small2"],
            },
          ],
          // {
          //   text: "Experience : " + "experience.experience" + " Months",
          //   alignment: "right",
          // },
        ],
        styles: {
          // small: {
          //   fontSize: 45,
          // },
          bold: {
            bold: true,
            fontSize: 8,
            italics: true,
            color: "lightgrey",
          },
        },
      },
    ]);

    return {
      table: {
        widths: ["*"],
        body: [...exs],
      },
    };
  }

  getPersonalObject1() {
    const data = this.updatedPersonalDuplicateDetail;
    console.log(data);
    if (!data) {
      return;
    }
    // Object.keys(data).forEach((key) => {
    //   console.log(key);

    //   if (data[key] === undefined) {
    //     data[key] = "NA";
    //   }
    // });
    if (data.length == 0) {
      const exs = [];

      exs.push([
        {
          columns: [
            [
              {
                text: "SECTION 1: PERSONAL DETAIL",
                bold: true,
                fontSize: 8,
                alignment: "center",
                // margin: [0, 0, 50, 0],
              },
              "\n",
              {
                text: "BORROWER/ DIRECTOR 2 DETAILS",
                bold: true,
                fontSize: 8,
                alignment: "center",
                // margin: [0, 0, 50, 0],
              },
              "\n",
              {
                text: `Title: Mr/ Mrs/ Miss/ Ms/ Other: ${data.title1}`,
                style: ["small1"],
              },
              {
                text: `First Name: ${data.firstname1}`,
                style: ["small1"],
              },
              {
                text: `Middle Name: ${data.middlename1}`,
                style: ["small1"],
              },
              {
                text: `Surname: ${data.sirname1}`,
                style: ["small1"],
              },
              {
                text: `Date of Birth: ${data.dob1}`,
                style: ["small1"],
              },
              {
                text: `Anticapted Retirement Age: ${data.anticipatedRetirementAge1}`,
                style: ["small1"],
              },
              {
                text: `Nationality:: ${data.nationality1}`,
                style: ["small1"],
              },
              {
                text: `National Insurance Number: ${data.nationalInsuranceNumber1}`,
                style: ["small1"],
              },
              {
                text: `Marital Status: ${data.martialStatus1}`,
                style: ["small1"],
              },
              {
                text: `No of Dependants: ${data.numberOfDependants1}`,
                style: ["small1"],
              },
              {
                text: `Home Address: ${data.homeAddress1}`,
                style: ["small1"],
              },
              {
                text: `Postcode: ${data.postcode1}`,
                style: ["small1"],
              },
              {
                text: `How long at this address: years months:${data.howLongAtThisAddress1}`,
                style: ["small1"],
              },
              {
                text: `Telephone: ${data.telephone1}`,
                style: ["small1"],
              },
              {
                text: `Email:  ${data.email1}`,
                style: ["small1"],
              },
              {
                text: `Employment Status: ${data.employementStatus1}`,
                style: ["small1"],
              },
              {
                text: `Job Title: ${data.jobTitle1}`,
                style: ["small1"],
              },
              {
                text: `CREDIT HISTORY, have you ever: ${data.creditHistroy1} `,
                style: ["small1"],
              },
              {
                text: `Been declared bankrupt?: ${data.select11}`,
                style: ["small2"],
              },
              {
                text: `Failed to keep up with repayments on a mortgage, credit card or
                other financial agreement?: ${data.select21}`,
                style: ["small2"],
              },
              {
                text: `Had a court order or debt registered against you?:  ${data.select31}`,
                style: ["small2"],
              },
              {
                text: `Had a property repossessed?: ${data.select41}`,
                style: ["small2"],
              },
              {
                text: `Broken any credit agreements?:  ${data.select51}`,
                style: ["small2"],
              },
              {
                text: `Been subject to an individual voluntary agreement (IVA)?: ${data.select61}`,
                style: ["small2"],
              },
              {
                text: `Been associated with a business that has been involved in one or
                more of the above?:  ${data.select71}`,
                style: ["small2"],
              },
            ],
            // {
            //   text: "Experience : " + "experience.experience" + " Months",
            //   alignment: "right",
            // },
          ],
          styles: {
            // small: {
            //   fontSize: 45,
            // },
            bold: {
              bold: true,
              fontSize: 8,
              italics: true,
              color: "lightgrey",
            },
          },
        },
      ]);

      return {
        table: {
          widths: ["*"],
          body: [...exs],
        },
      };
    }
    const exs = [];

    exs.push([
      {
        columns: [
          [
            {
              text: "SECTION 1: PERSONAL DETAIL",
              bold: true,
              fontSize: 8,
              alignment: "center",
              // margin: [0, 0, 50, 0],
            },
            "\n",
            {
              text: "BORROWER/ DIRECTOR 1 DETAILS",
              bold: true,
              fontSize: 8,
              alignment: "center",
              // margin: [0, 0, 50, 0],
            },
            "\n",
            {
              text: `Title: Mr/ Mrs/ Miss/ Ms/ Other: ${data.title1}`,
              style: ["small1"],
            },
            {
              text: `First Name: ${data.firstname1}`,
              style: ["small1"],
            },
            {
              text: `Middle Name: ${data.middlename1}`,
              style: ["small1"],
            },
            {
              text: `Surname: ${data.sirname1}`,
              style: ["small1"],
            },
            {
              text: `Date of Birth: ${data.dob1}`,
              style: ["small1"],
            },
            {
              text: `Anticapted Retirement Age: ${data.anticipatedRetirementAge1}`,
              style: ["small1"],
            },
            {
              text: `Nationality:: ${data.nationality1}`,
              style: ["small1"],
            },
            {
              text: `National Insurance Number: ${data.nationalInsuranceNumber1}`,
              style: ["small1"],
            },
            {
              text: `Marital Status: ${data.martialStatus1}`,
              style: ["small1"],
            },
            {
              text: `No of Dependants: ${data.numberOfDependants1}`,
              style: ["small1"],
            },
            {
              text: `Home Address: ${data.homeAddress1}`,
              style: ["small1"],
            },
            {
              text: `Postcode: ${data.postcode1}`,
              style: ["small1"],
            },
            {
              text: `How long at this address: years months:${data.howLongAtThisAddress1}`,
              style: ["small1"],
            },
            {
              text: `Telephone: ${data.telephone1}`,
              style: ["small1"],
            },
            {
              text: `Email:  ${data.email1}`,
              style: ["small1"],
            },
            {
              text: `Employment Status: ${data.employementStatus1}`,
              style: ["small1"],
            },
            {
              text: `Job Title: ${data.jobTitle1}`,
              style: ["small1"],
            },
            {
              text: `CREDIT HISTORY, have you ever: ${data.creditHistroy1} `,
              style: ["small1"],
            },
            {
              text: `Been declared bankrupt?: ${data.select11}`,
              style: ["small2"],
            },
            {
              text: `Failed to keep up with repayments on a mortgage, credit card or
              other financial agreement?: ${data.select21}`,
              style: ["small2"],
            },
            {
              text: `Had a court order or debt registered against you?:  ${data.select31}`,
              style: ["small2"],
            },
            {
              text: `Had a property repossessed?: ${data.select41}`,
              style: ["small2"],
            },
            {
              text: `Broken any credit agreements?:  ${data.select51}`,
              style: ["small2"],
            },
            {
              text: `Been subject to an individual voluntary agreement (IVA)?: ${data.select61}`,
              style: ["small2"],
            },
            {
              text: `Been associated with a business that has been involved in one or
              more of the above?:  ${data.select71}`,
              style: ["small2"],
            },
          ],
          // {
          //   text: "Experience : " + "experience.experience" + " Months",
          //   alignment: "right",
          // },
        ],
        styles: {
          // small: {
          //   fontSize: 45,
          // },
          bold: {
            bold: true,
            fontSize: 8,
            italics: true,
            color: "lightgrey",
          },
        },
      },
    ]);

    return {
      table: {
        widths: ["*"],
        body: [...exs],
      },
    };
  }

  getFinancialObject() {
    const data = this.updateFinancialDetail;
    if (!data) {
      return;
    }
    const exs = [];

    exs.push([
      {
        columns: [
          [
            {
              text: "SECTION 2: BORROWER 1 FINANCIAL DOCUMENT",
              bold: true,
              fontSize: 8,
              alignment: "center",
              // margin: [0, 0, 50, 0],
            },
            "\n",
            {
              text: "ASSETS",
              bold: true,
              fontSize: 10,
              alignment: "center",
              margin: [0, 0, 50, 0],
            },
            {
              text: `Main Residence £: ${data.mainResidence}`,
              style: ["small"],
            },
            {
              text: `Total other properties £: ${data.totalOtherProp}`,
              style: ["small"],
            },
            {
              text: `Life Policies (surrender value) £ ${data.lifePolicies}`,
              style: ["small"],
            },
            {
              text: `Stocks/ Shares/ Savings £: ${data.Stock}`,
              style: ["small"],
            },
            {
              text: `Type: ${data.type}`,
              style: ["small"],
            },
            {
              text: `Other £: ${data.Other}`,
              style: ["small"],
            },
            {
              text: `Total Asset: ${data.totalAssets}`,
              bold: true,
              fontSize: 10,
            },
            {
              text: `LIABILITIES: ${data.liabilities}`,
              bold: true,
              fontSize: 10,
              alignment: "center",
              margin: [0, 0, 50, 0],
            },
            "\n",
            {
              text: `Main Residence Mortgage £ : ${data.mainResidenceMortage}`,
              style: ["small"],
            },
            {
              text: `Lender: ${data.lender}`,
              style: ["small"],
            },
            {
              text: `Total other property mortgages £: ${data.totalOtherPropertyMortage}`,
              style: ["small"],
            },
            {
              text: `Bank overdraft £: ${data.bankOverdraft}`,
              style: ["small"],
            },
            {
              text: `Loans £: ${data.loans}`,
              style: ["small"],
            },
            {
              text: `HP/ Leasing o/s balance £: ${data.hpLeasingBalance}`,
              style: ["small"],
            },
            {
              text: `Credit/ store cards £: ${data.creditCard}`,
              style: ["small"],
            },
            {
              text: `Provider: ${data.provider}`,
              style: ["small"],
            },
            {
              text: `Other £: ${data.other1}`,
              style: ["small"],
            },
            {
              text: `Type: ${data.type2}`,
              style: ["small"],
            },
            {
              text: `TOTAL LIABILITIES £: ${data.totalLiabilities}`,
              bold: true,
              fontSize: 10,
            },
            {
              text: `TOTAL NET WORTH £: ${data.totalNetWorth}`,
              bold: true,
              fontSize: 10,
            },
            {
              text: `(Assets minus liabilities): ${data.assetMinusLiabilities}`,
              style: ["small"],
            },
            {
              text: "INCOME",
              bold: true,
              fontSize: 10,
              alignment: "center",
              margin: [0, 0, 50, 0],
            },
            "\n",
            {
              text: `Main income/ Drawings £: ${data.mainIncome}`,
              style: ["small"],
            },
            {
              text: `Spouse/ Partner income £: ${data.spouseIncome}`,
              style: ["small"],
            },
            {
              text: `Total rental income £: ${data.totalRentIncome}`,
              style: ["small"],
            },
            {
              text: `Other Income £: ${data.otherIncome}`,
              style: ["small"],
            },
            {
              text: `Type: ${data.type4}`,
              style: ["small"],
            },
            {
              text: `TOTAL MONTHLY INCOME £: ${data.totalMonthlyIncome}`,
              bold: true,
              fontSize: 10,
            },
            {
              text: "EXPENDITURE",
              bold: true,
              fontSize: 10,
              alignment: "center",
              margin: [0, 0, 50, 0],
            },
            "\n",
            {
              text: `Mortgage/ Rent £ : ${data.rent}`,
              style: ["small"],
            },
            {
              text: `Other mortgage payments £: ${data.otherMortagePayment}`,
              style: ["small"],
            },
            {
              text: `Loan/ Hire purchase payments £: ${data.loanPurchasePayment}`,
              style: ["small"],
            },

            {
              text: `Credit card payments £: ${data.creditCardPayments}`,
              style: ["small"],
            },
            {
              text: `Council Tax/ Water £: ${data.CouncilTax}`,
              style: ["small"],
            },
            {
              text: ` Life insurance £: ${data.lifeInsurance}`,
              style: ["small"],
            },
            {
              text: ` Buildings/ Contents Insurance £: ${data.contentsInsurance}`,
              style: ["small"],
            },
            {
              text: `Domestic Fuel £: ${data.domesticFuel}`,
              style: ["small"],
            },
            {
              text: `Telephone/ Broadband/ Mobile £: ${data.telephone}`,
              style: ["small"],
            },
            {
              text: `Food £: ${data.food}`,
              style: ["small"],
            },
            {
              text: ` Car and travelling expenses £: ${data.carAndTravellingExpense}`,
              style: ["small"],
            },
            {
              text: `Clothing/ Hobbies/ Leisure £: ${data.leisure}`,
              style: ["small"],
            },
            {
              text: ` Other £: ${data.otherExp}`,
              style: ["small"],
            },
            {
              text: ` Type: ${data.typeExp}`,
              style: ["small"],
            },
            {
              text: ` TOTAL EXPENDITURE £: ${data.totalExpenditure}`,
              bold: true,
              fontSize: 10,
            },
            {
              text: ` MONTHLY SURPLUS £: ${data.monthlySurplus}`,
              style: ["small"],
            },
          ],
          // {
          //   text: "Experience : " + "experience.experience" + " Months",
          //   alignment: "right",
          // },
        ],
        styles: {
          // bold: {
          //   bold: true,
          //   fontSize: 8,
          //   italics: true,
          //   color: "lightgrey",
          // },
        },
      },
    ]);

    return {
      table: {
        widths: ["*"],
        body: [...exs],
      },
    };
  }

  getFinancialObject1() {
    const data = this.updateDuplicateFinancialDetailS;
    if (!data) {
      return;
    }
    if (data.length === 0) {
      const exs = [];
      exs.push([
        {
          columns: [
            [
              {
                text: "SECTION 2: BORROWER 1 FINANCIAL DOCUMENT",
                bold: true,
                fontSize: 8,
                alignment: "center",
                // margin: [0, 0, 50, 0],
              },
              "\n",
              {
                text: "ASSETS",
                bold: true,
                fontSize: 10,
                alignment: "center",
                margin: [0, 0, 50, 0],
              },
              {
                text: `Main Residence £: NA`,
                style: ["small"],
              },
              {
                text: `Total other properties £: NA`,
                style: ["small"],
              },
              {
                text: `Life Policies (surrender value) £ : NA`,
                style: ["small"],
              },
              {
                text: `Stocks/ Shares/ Savings £: NA`,
                style: ["small"],
              },
              {
                text: `Type: NA`,
                style: ["small"],
              },
              {
                text: `Other £: NA`,
                style: ["small"],
              },
              {
                text: `Total Asset: NA`,
                bold: true,
                fontSize: 10,
              },
              {
                text: `LIABILITIES: NA`,
                bold: true,
                fontSize: 10,
                alignment: "center",
                margin: [0, 0, 50, 0],
              },
              "\n",
              {
                text: `Main Residence Mortgage £ : NA`,
                style: ["small"],
              },
              {
                text: `Lender: NA`,
                style: ["small"],
              },
              {
                text: `Total other property mortgages £: NA`,
                style: ["small"],
              },
              {
                text: `Bank overdraft £: NA`,
                style: ["small"],
              },
              {
                text: `Loans £: NA`,
                style: ["small"],
              },
              {
                text: `HP/ Leasing o/s balance £: NA`,
                style: ["small"],
              },
              {
                text: `Credit/ store cards £: NA`,
                style: ["small"],
              },
              {
                text: `Provider: NA`,
                style: ["small"],
              },
              {
                text: `Other £: NA`,
                style: ["small"],
              },
              {
                text: `Type: NA`,
                style: ["small"],
              },
              {
                text: `TOTAL LIABILITIES £: NA`,
                bold: true,
                fontSize: 10,
              },
              {
                text: `TOTAL NET WORTH £: NA`,
                bold: true,
                fontSize: 10,
              },
              {
                text: `(Assets minus liabilities): NA`,
                style: ["small"],
              },
              {
                text: "INCOME",
                bold: true,
                fontSize: 10,
                alignment: "center",
                margin: [0, 0, 50, 0],
              },
              "\n",
              {
                text: `Main income/ Drawings £: NA`,
                style: ["small"],
              },
              {
                text: `Spouse/ Partner income £: NA`,
                style: ["small"],
              },
              {
                text: `Total rental income £: NA`,
                style: ["small"],
              },
              {
                text: `Other Income £: NA`,
                style: ["small"],
              },
              {
                text: `Type: NA`,
                style: ["small"],
              },
              {
                text: `TOTAL MONTHLY INCOME £: NA`,
                bold: true,
                fontSize: 10,
              },
              {
                text: "EXPENDITURE",
                bold: true,
                fontSize: 10,
                alignment: "center",
                margin: [0, 0, 50, 0],
              },
              "\n",
              {
                text: `Mortgage/ Rent £ : NA`,
                style: ["small"],
              },
              {
                text: `Other mortgage payments £: NA`,
                style: ["small"],
              },
              {
                text: `Loan/ Hire purchase payments £: NA`,
                style: ["small"],
              },

              {
                text: `Credit card payments £: NA`,
                style: ["small"],
              },
              {
                text: `Council Tax/ Water £: NA`,
                style: ["small"],
              },
              {
                text: ` Life insurance £: NA`,
                style: ["small"],
              },
              {
                text: ` Buildings/ Contents Insurance £: NA`,
                style: ["small"],
              },
              {
                text: `Domestic Fuel £: NA`,
                style: ["small"],
              },
              {
                text: `Telephone/ Broadband/ Mobile £: NA`,
                style: ["small"],
              },
              {
                text: `Food £: NA`,
                style: ["small"],
              },
              {
                text: ` Car and travelling expenses £: NA`,
                style: ["small"],
              },
              {
                text: `Clothing/ Hobbies/ Leisure £: NA`,
                style: ["small"],
              },
              {
                text: ` Other £: NA`,
                style: ["small"],
              },
              {
                text: ` Type: NA`,
                style: ["small"],
              },
              {
                text: ` TOTAL EXPENDITURE £: NA`,
                bold: true,
                fontSize: 10,
              },
              {
                text: ` MONTHLY SURPLUS £: NA`,
                style: ["small"],
              },
            ],
            // {
            //   text: "Experience : " + "experience.experience" + " Months",
            //   alignment: "right",
            // },
          ],
          styles: {
            // bold: {
            //   bold: true,
            //   fontSize: 8,
            //   italics: true,
            //   color: "lightgrey",
            // },
          },
        },
      ]);

      return {
        table: {
          widths: ["*"],
          body: [...exs],
        },
      };
    }
    const exs = [];
    exs.push([
      {
        columns: [
          [
            {
              text: "SECTION 2: BORROWER 2 FINANCIAL DOCUMENT",
              bold: true,
              fontSize: 8,
              alignment: "center",
              // margin: [0, 0, 50, 0],
            },
            "\n",
            {
              text: "ASSETS",
              bold: true,
              fontSize: 10,
              alignment: "center",
              margin: [0, 0, 50, 0],
            },
            {
              text: `Main Residence £: ${data.mainResidence1}`,
              style: ["small"],
            },
            {
              text: `Total other properties £: ${data.totalOtherProp1}`,
              style: ["small"],
            },
            {
              text: `Life Policies (surrender value) £ ${data.lifePolicies1}`,
              style: ["small"],
            },
            {
              text: `Stocks/ Shares/ Savings £: ${data.Stock1}`,
              style: ["small"],
            },
            {
              text: `Type: ${data.type1}`,
              style: ["small"],
            },
            {
              text: `Other £: ${data.Other1}`,
              style: ["small"],
            },
            {
              text: `Total Asset: ${data.totalAssets1}`,
              bold: true,
              fontSize: 10,
            },
            {
              text: `LIABILITIES: ${data.liabilities1}`,
              bold: true,
              fontSize: 10,
              alignment: "center",
              margin: [0, 0, 50, 0],
            },
            "\n",
            {
              text: `Main Residence Mortgage £ : ${data.mainResidenceMortage1}`,
              style: ["small"],
            },
            {
              text: `Lender: ${data.lender1}`,
              style: ["small"],
            },
            {
              text: `Total other property mortgages £: ${data.totalOtherPropertyMortage1}`,
              style: ["small"],
            },
            {
              text: `Bank overdraft £: ${data.bankOverdraft1}`,
              style: ["small"],
            },
            {
              text: `Loans £: ${data.loans1}`,
              style: ["small"],
            },
            {
              text: `HP/ Leasing o/s balance £: ${data.hpLeasingBalance1}`,
              style: ["small"],
            },
            {
              text: `Credit/ store cards £: ${data.creditCard1}`,
              style: ["small"],
            },
            {
              text: `Provider: ${data.provider1}`,
              style: ["small"],
            },
            {
              text: `Other £: ${data.other11}`,
              style: ["small"],
            },
            {
              text: `Type: ${data.type21}`,
              style: ["small"],
            },
            {
              text: `TOTAL LIABILITIES £: ${data.totalLiabilities1}`,
              bold: true,
              fontSize: 10,
            },
            {
              text: `TOTAL NET WORTH £: ${data.totalNetWorth1}`,
              bold: true,
              fontSize: 10,
            },
            {
              text: `(Assets minus liabilities): ${data.assetMinusLiabilities1}`,
              style: ["small"],
            },
            {
              text: "INCOME",
              bold: true,
              fontSize: 10,
              alignment: "center",
              margin: [0, 0, 50, 0],
            },
            "\n",
            {
              text: `Main income/ Drawings £: ${data.mainIncome1}`,
              style: ["small"],
            },
            {
              text: `Spouse/ Partner income £: ${data.spouseIncome1}`,
              style: ["small"],
            },
            {
              text: `Total rental income £: ${data.totalRentIncome1}`,
              style: ["small"],
            },
            {
              text: `Other Income £: ${data.otherIncome1}`,
              style: ["small"],
            },
            {
              text: `Type: ${data.type41}`,
              style: ["small"],
            },
            {
              text: `TOTAL MONTHLY INCOME £: ${data.totalMonthlyIncome1}`,
              bold: true,
              fontSize: 10,
            },
            {
              text: "EXPENDITURE",
              bold: true,
              fontSize: 10,
              alignment: "center",
              margin: [0, 0, 50, 0],
            },
            "\n",
            {
              text: `Mortgage/ Rent £ : ${data.rent1}`,
              style: ["small"],
            },
            {
              text: `Other mortgage payments £: ${data.otherMortagePayment1}`,
              style: ["small"],
            },
            {
              text: `Loan/ Hire purchase payments £: ${data.loanPurchasePayment1}`,
              style: ["small"],
            },

            {
              text: `Credit card payments £: ${data.creditCardPayments1}`,
              style: ["small"],
            },
            {
              text: `Council Tax/ Water £: ${data.CouncilTax1}`,
              style: ["small"],
            },
            {
              text: ` Life insurance £: ${data.lifeInsurance1}`,
              style: ["small"],
            },
            {
              text: ` Buildings/ Contents Insurance £: ${data.contentsInsurance1}`,
              style: ["small"],
            },
            {
              text: `Domestic Fuel £: ${data.domesticFuel1}`,
              style: ["small"],
            },
            {
              text: `Telephone/ Broadband/ Mobile £: ${data.telephone1}`,
              style: ["small"],
            },
            {
              text: `Food £: ${data.food1}`,
              style: ["small"],
            },
            {
              text: ` Car and travelling expenses £: ${data.carAndTravellingExpense1}`,
              style: ["small"],
            },
            {
              text: `Clothing/ Hobbies/ Leisure £: ${data.leisure1}`,
              style: ["small"],
            },
            {
              text: ` Other £: ${data.otherExp1}`,
              style: ["small"],
            },
            {
              text: ` Type: ${data.typeExp1}`,
              style: ["small"],
            },
            {
              text: ` TOTAL EXPENDITURE £: ${data.totalExpenditure1}`,
              bold: true,
              fontSize: 10,
            },
            {
              text: ` MONTHLY SURPLUS £: ${data.monthlySurplus1}`,
              style: ["small"],
            },
          ],
          // {
          //   text: "Experience : " + "experience.experience" + " Months",
          //   alignment: "right",
          // },
        ],
        styles: {
          // bold: {
          //   bold: true,
          //   fontSize: 8,
          //   italics: true,
          //   color: "lightgrey",
          // },
        },
      },
    ]);

    return {
      table: {
        widths: ["*"],
        body: [...exs],
      },
    };
  }

  // generatePdf(action = "download") {
  //   var documentDefinition = this.pdfdata;
  //   // pdfMake.createPdf(documentDefinition).download("file.pdf");
  //   // console.log(documentDefinition);
  //   // pdfMake.createPdf(documentDefinition).open();

  //   switch (action) {
  //     case "download":
  //       pdfMake.createPdf(documentDefinition).download("file.pdf");
  //       break;
  //     default:
  //       pdfMake.createPdf(documentDefinition).open();
  //       break;
  //   }
  // }
}
