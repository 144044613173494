import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json',
    // 'authorization': 'true'
  })
};
@Injectable({
  providedIn: 'root'
})

export class UserService {
  url = 'https://api.platinumspecialistfinance.co.uk'
  
  constructor(private http: HttpClient) { }

 public saveuserdata(data) {
    return this.http.post(this.url+'/saveuserdata',data,httpOptions);
  }
  public getallusers() {

    return this.http.get(this.url+'/getuserdata',httpOptions);
  }
}
