import { NgModule } from "@angular/core";
import { PreloadAllModules, RouterModule, Routes } from "@angular/router";
import { AuthGuard } from "./guard/auth.guard";

const routes: Routes = [
  {
    path: "",
    redirectTo: "login",
    pathMatch: "full",
  },
  {
    path: "folder/:id",
    loadChildren: () =>
      import("./folder/folder.module").then((m) => m.FolderPageModule),
  },
  {
    path: "login",
    loadChildren: () =>
      import("./login/login.module").then((m) => m.LoginPageModule),
  },
  {
    path: "signup",
    loadChildren: () =>
      import("./signup/signup.module").then((m) => m.SignupPageModule),
  },
  {
    path: "mortgage-calculator",
    loadChildren: () =>
      import("./mortgage-calculator/mortgage-calculator.module").then(
        (m) => m.MortgageCalculatorPageModule
      ),
  },

  {
    path: "my-personal-finance",
    loadChildren: () =>
      import("./my-personal-finance/my-personal-finance.module").then(
        (m) => m.MyPersonalFinancePageModule
      ),
  },
  {
    path: "dashboard",
    loadChildren: () =>
      import("./dashboard/dashboard.module").then((m) => m.DashboardPageModule),
  },
  {
    path: "loan-detail",
    loadChildren: () =>
      import("./loan-detail/loan-detail.module").then(
        (m) => m.LoanDetailPageModule
      ),
  },
  {
    path: "result",
    loadChildren: () =>
      import("./result/result.module").then((m) => m.ResultPageModule),
  },
  {
    path: "all-users",
    loadChildren: () =>
      import("./all-users/all-users.module").then((m) => m.AllUsersPageModule),
  },
  {
    path: "application-form",
    loadChildren: () =>
      import("./application-form/application-form.module").then(
        (m) => m.ApplicationFormPageModule
      ),
  },
  {
    path: "form-message",
    loadChildren: () =>
      import("./form-message/form-message.module").then(
        (m) => m.FormMessagePageModule
      ),
  },
  {
    path: "customer",
    loadChildren: () =>
      import("./customer/customer.module").then((m) => m.CustomerPageModule),
  },
  {
    path: "reset-password",
    loadChildren: () =>
      import("./reset-password/reset-password.module").then(
        (m) => m.ResetPasswordPageModule
      ),
  },
  {
    path: "forgot-password",
    loadChildren: () =>
      import("./forgot-password/forgot-password.module").then(
        (m) => m.ForgotPasswordPageModule
      ),
  },
  {
    path: "customermodal",
    loadChildren: () =>
      import("./customermodal/customermodal.module").then(
        (m) => m.CustomermodalPageModule
      ),
  },
  {
    path: "bank",
    loadChildren: () =>
      import("./bank/bank.module").then((m) => m.BankPageModule),
  },
  {
    path: "bank-detail/:id",
    loadChildren: () =>
      import("./bank-detail/bank-detail.module").then(
        (m) => m.BankDetailPageModule
      ),
  },
  {
    path: "application-form1",
    loadChildren: () =>
      import("./application-form1/application-form1.module").then(
        (m) => m.ApplicationForm1PageModule
      ),
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
